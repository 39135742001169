import React from "react"
import { Container, Row, Col } from "reactstrap"

const BlogBanner = () => {
  return (
    <div className="blog-banner pt-6 pb-6 mb-4 text-white">
      <Container fluid>
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            <h1 className="mb-5 font-weight-bold">Blog</h1>
            <p className="mb-5 text-justify lead">
              Foretheta is a very special remote-first company working on cutting edge technologies. We are continuously
              facing and resolving complex business and technology challenges. This blog is to share our experience and
              help others achieve success by finding a good answer to their challenges.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BlogBanner
