import React from "react"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const BlogPagination = ({ blogsPerPage, totalBlogs, paginate }) => {
  const pageNumbers = []
  for (let i = 1; i <= Math.ceil(totalBlogs / blogsPerPage); i++) {
    pageNumbers.push(i)
  }
  return (
    <Pagination aria-label="Page navigation example">
      {totalBlogs > blogsPerPage
        ? pageNumbers.map(number => (
            <PaginationItem key={number}>
              <PaginationLink onClick={() => paginate(number)}>{number}</PaginationLink>
            </PaginationItem>
          ))
        : null}
    </Pagination>
  )
}
export default BlogPagination
