import React, { useState, useEffect, useRef } from "react"
import { Container, Row, Col, Alert } from "reactstrap"
import { graphql } from "gatsby"
import HelmetTitle from "../components/utils"
import BlogList from "../components/Blog/BlogList"
import BlogBanner from "../components/Blog/BlogBanner"
import Layout from "../components/Layout"
import "../scss/modules/_m-hero-blog.scss"
import BlogPagination from "../components/Blog/BlogPagination"
import BlogSearch from "../components/Blog/BlogSearch"

const Blog = props => {
  const data = props.data.allDevArticles.edges
  const [currentPage, setCurrentPage] = useState(1)
  const [blogsPerPage] = useState(10)
  const [search, setSearch] = useState("")
  const [searchTags, setSearchTags] = useState("")
  const [searchedResults, setSearchResults] = useState("")
  const [tags] = useState([])
  const ref = useRef()

  useEffect(() => {
    const filteredBlogs = []
    data.forEach(blog => {
      return blog.node.article.tags.forEach(tag => tag === search && filteredBlogs.push(blog))
    })
    setSearchResults(filteredBlogs)
  }, [searchTags])
  useEffect(() => {
    window.scrollTo(0, ref.current.offsetTop)
  }, [currentPage])

  const indexOfLastBlog = currentPage * blogsPerPage
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage
  const currentBlogs =
    search === ""
      ? data.slice(indexOfFirstBlog, indexOfLastBlog)
      : searchedResults.slice(indexOfFirstBlog, indexOfLastBlog)

  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const handleChange = e => {
    setCurrentPage(1)
    setSearch(e.target.value)
    const filteredBlogs = data.filter(blog => {
      return blog.node.article.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    })
    tags.forEach(tag => {
      if (tag.includes(e.target.value)) {
        data.filter(blog => {
          return blog.node.article.tags.filter(blogTag => {
            tag === blogTag && !filteredBlogs.includes(blog) ? filteredBlogs.push(blog) : null
          })
        })
      }
    })
    setSearchResults(filteredBlogs)
  }
  const handleClick = e => {
    setCurrentPage(1)
    setSearch(e.target.textContent)
    setSearchTags(e.target.textContent)
  }

  data.forEach(({ node: { article } }) => {
    article.tags.filter(tag => {
      tags.indexOf(tag) === -1 ? tags.push(tag) : null
    })
  })

  return (
    <Layout>
      <div className="blog mb-6">
        <HelmetTitle title="Blog" />
        <BlogBanner />
        <div ref={ref}></div>
        <Container>
          <BlogSearch handleChange={handleChange} search={search} tags={tags} handleClick={handleClick} />
          <Row>
            {currentBlogs.map(({ node: { article } }) => {
              return (
                <Col key={article.id} lg="6" className="p-md-4 py-4">
                  <BlogList
                    coverImage={article.cover_image || "/cover-image-placeholder.jpg"}
                    title={article.title}
                    date={article.published_at}
                    description={article.description}
                    link={`/blog/${article.slug}`}
                    tags={article.tags}
                  />
                </Col>
              )
            })}
          </Row>
          {currentBlogs.length === 0 ? <Alert color="info">No search results found!</Alert> : null}
          <Row className="row pt-5 justify-content-center">
            {search === "" ? (
              <BlogPagination blogsPerPage={blogsPerPage} totalBlogs={data.length} paginate={paginate} />
            ) : (
              <BlogPagination blogsPerPage={blogsPerPage} totalBlogs={searchedResults.length} paginate={paginate} />
            )}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export const BlogIndex = graphql`
  query BlogIndex {
    allDevArticles(sort: { fields: article___published_at, order: DESC }) {
      edges {
        node {
          article {
            id
            slug
            title
            published_at(formatString: "LL")
            description
            cover_image
            tags
          }
        }
      }
    }
  }
`

export default Blog
