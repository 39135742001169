import React from "react"
import { Link } from "gatsby"
import { Card, CardImg, CardBody, CardTitle, CardText, Badge } from "reactstrap"
import "./styles.scss"

const BlogList = ({ title, date, description, link, coverImage, tags }) => {
  return (
    <Card className="post-card-container">
      <Link to={link}>
        <CardImg top width="100%" src={coverImage} />
      </Link>
      <CardBody>
        <div className="pb-2 post-card-date">
          <time>{date}</time>
        </div>
        <Link to={link}>
          <CardTitle className="post-card-title">{title}</CardTitle>
        </Link>
        {tags.map(tag => {
          return (
            <Badge color="dark" className="mr-1" key={tag} value={tag}>
              {tag}
            </Badge>
          )
        })}
        <CardText className="post-card-description mt-3">{description}</CardText>
        <Link to={link}>
          <button className="text-uppercase rounded post-card-btn">Read More</button>
        </Link>
      </CardBody>
    </Card>
  )
}

export default BlogList
