import React from "react"
import { Input, Badge } from "reactstrap"

export default function BlogSearch({ handleChange, search, tags, handleClick }) {
  return (
    <div className="tag_badge">
      <Input
        type="search"
        name="search"
        value={search}
        placeholder="Search Blogs"
        onChange={handleChange}
        className="mb-3"
      />
      {tags.map(tag => {
        return (
          <Badge color={tag === search ? "dark" : "light"} className="mr-1" key={tag} onClick={handleClick} value={tag}>
            {tag}
          </Badge>
        )
      })}
    </div>
  )
}
